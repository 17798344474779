import { URLPREFIXES } from '@constants/links';
import { SideNavNode } from '@features/sideNav/useSideNavData/useSideNavData';
import useCategoryNavTree from '@features/categories/useCategoryNavTree';

const mapCategoryNodes = (nodes: any[], parents: string[], trackingPath: string): SideNavNode[] => {
  return nodes.map((node) => mapCategoryNode(node, parents, trackingPath));
};

// eslint-disable-next-line fp/no-rest-parameters
const mapId = (...levels: string[]) => {
  return levels.map((s) => s.trim()).join(' | ');
};

const mapCategoryNode = (node: any, parentIds: string[], trackingPath: string): SideNavNode => {
  const { id: categoryId, title, url: urlPath } = node;
  const url = URLPREFIXES.CATEGORIES + urlPath;
  const trackingId = trackingPath ? mapId(trackingPath, title) : title;

  const id = mapId(categoryId, title);

  return {
    id,
    title,
    url,
    trackingId,
    parentIds,
    childNodes: mapCategoryNodes(node.children, [...parentIds, id], trackingId),
  };
};

/**
 * Converts category navigation tree to nodes
 */
const useAssortmentLinks = () => {
  const { data } = useCategoryNavTree();

  if (!data || !data.children) {
    return []
  }

  return mapCategoryNodes(data.children, [], '');
};

export default useAssortmentLinks;
