import Container from '@components/organisms/Container';
import SideNav from '@features/sideNav';
import React from 'react';
import { PageContentWrapper, PageWrapper } from './LayoutPageContainer.styles';

interface Props {
  children: React.ReactNode;
  withSideNav?: boolean;
}

const LayoutPageContainer = ({ children, withSideNav = true }: Props) => {
  return (
    <PageContentWrapper>
      <Container>
        {withSideNav && <SideNav />}
        <PageWrapper>{children}</PageWrapper>
      </Container>
    </PageContentWrapper>
  );
};

export default LayoutPageContainer;
