import useAssortmentLinks from '@features/sideNav/useSideNavData/useAssortmentLinks';
import useCmsLinks from '@features/sideNav/useSideNavData/useCmsLinks';
import useAppRouter from '@hooks/useAppRouter';
import usePageHasSideNav from '@hooks/usePageHasSideNav';
import { useCallback } from 'react';

export interface SideNavNode {
  id: string;
  title: string;
  url: string;
  trackingId: string;
  parentIds: Array<string>;
  childNodes: Array<SideNavNode>;
}

const traverse = (node: SideNavNode): SideNavNode[] => [node, ...node.childNodes.flatMap(traverse)];

const getAllNodes = (nodes: SideNavNode[]): SideNavNode[] => {
  return nodes.flatMap(traverse);
};

const findNodeFromUrl = (url: string, nodes: SideNavNode[]): SideNavNode | undefined => {
  return getAllNodes(nodes).find((n) => n.url === url);
};

const findNodeFromId = (id: string, nodes: SideNavNode[]): SideNavNode | undefined => {
  return getAllNodes(nodes).find((n) => n.id === id);
};

const useSideNavData = () => {
  const { pathname = '' } = useAppRouter();
  const pageHasSideNav = usePageHasSideNav();
  const links = useCmsLinks();
  const assortment = useAssortmentLinks();
  const nodes = [...(links || []), ...(assortment || [])];
  const selectedId = findNodeFromUrl(pathname, nodes)?.id;

  const getNode = useCallback((id: string) => findNodeFromId(id, nodes), [links, assortment]);

  return { pageHasSideNav, links, assortment, selectedId, getNode };
};

export default useSideNavData;
