import useAssortmentStore from '@hooks/useAssortmentStore';
import { LeftMenuApi } from '@occ/api-client';
import useSWR from 'swr';
import useCustomer from '@hooks/useCustomer';

const api = new LeftMenuApi({
  baseUrl: ''
})

interface CategoryTree {
  id?: string,
  category?: string,
  title?: string,
  url?: string,
  children?: CategoryTree[]
}

const fetcher = async ({ storeId }: { storeId: string }) => {
  const response = await api.getCategoryNavigationTreeUsingGet({ storeId });
  return response.data as CategoryTree;
};

const useCategoryNavTree = () => {

  const { customerFetchIsRequired } = useCustomer();
  const assortmentStoreId = useAssortmentStore();

  const key = {
    endpoint: '/leftMenu/categorytree',
    storeId: assortmentStoreId,
  };

  const shouldFetch = customerFetchIsRequired;
  const { data } = useSWR(shouldFetch ? key as { storeId: string } : null, fetcher)

  return {
    data
  }
};

export default useCategoryNavTree;